import dateHelper from '../utils/helper/date-helper';
import numberHelper from '../utils/helper/number-helper';
import urlHelper from '../utils/helper/url-helper';
import salesHelper from '../utils/helper/sales-helper';

let configService;
let productService;
let customProductServices;

function setDependencies(dependencies) {
  ({ configService, productService, customProductServices } = dependencies);
  return this;
}

function getSalesConfig() {
  return configService.getGeneralConfig().sales;
}

function importCart(positions, dataStorage, dataStorageService) {
  const importArray = [];
  if (Array.isArray(positions)) {
    positions.forEach((position) => {
      if (position.articleId && numberHelper.canBeConvertedToNumber(position.quantity)) {
        if (position.customProductType) {
          if (customProductServices[position.customProductType]
            && position.flatConfiguration
            && dataStorageService.customProductIsAvailable(
              position.flatConfiguration, dataStorage.products
            )
          ) {
            importArray.push({
              product: {
                id: position.articleId,
                flatConfiguration: JSON.parse(position.flatConfiguration),
              },
              orderQuantity: position.quantity,
              customProductType: position.customProductType,
            });
          }
        } else {
          const newProduct = productService.getProduct(position.articleId, dataStorage);
          if (newProduct) {
            importArray.push({ product: newProduct, orderQuantity: position.quantity });
          }
        }
      }
    });
    if (importArray.length > 0) {
      dataStorageService.addToCart({ dataStorage, positions: importArray });
    }
  }
  return importArray.length;
}

function reorderCart(positions, dataStorage, dataStorageService) {
  const reorderArray = [];
  positions.forEach((position) => {
    if (position.customProductType) {
      const flatConfig = customProductServices[position.customProductType].getFlatConfiguration(
        position.product.customConfiguration
      );
      if (customProductServices[position.customProductType]
        && dataStorageService.customProductIsAvailable(
          JSON.stringify(flatConfig), dataStorage.products
        )
      ) {
        reorderArray.push({
          product: {
            id: position.product.id,
            flatConfiguration: flatConfig,
          },
          orderQuantity: position.qty,
          customProductType: position.customProductType,
        });
      }
    } else {
      const newProduct = productService.getProduct(position.product.id, dataStorage);
      if (newProduct) {
        reorderArray.push({ product: newProduct, orderQuantity: position.qty });
      }
    }
  });
  if (reorderArray.length > 0) {
    dataStorageService.addToCart({ dataStorage, positions: reorderArray });
  }
  return reorderArray.length;
}

function getTermsAndConditionsPath(locale) {
  return configService.getGeneralDocumentPath(
    getSalesConfig().legal.termsAndConditions.filename[locale]
  );
}

function getDefaultCheckoutSteps() {
  return [
    {
      componentId: 'delivery',
      labelTranslationId: 'shippingInformation',
    },
    {
      componentId: 'payment',
      labelTranslationId: 'paymentInformation',
    },
    {
      componentId: 'order',
      labelTranslationId: 'order',
    },
  ];
}

function getOrderByUrlQuery(orders) {
  return urlHelper.getItemByUrlQuery('oid', 'id', orders);
}

function getFormattedOrderDate(orderDate, locale) {
  return (new Date(orderDate.substr(0, 10))).toLocaleDateString(
    locale, {
      year: 'numeric', month: 'numeric', day: 'numeric',
    }
  );
}

function getPositionTitle(product, locale, intl, customProductType) {
  if (customProductType) {
    return customProductServices[customProductType].getCustomProductTitle(
      product, intl, locale
    );
  }
  return product.name[locale];
}

function getPositionLink(product, locale, customProductType) {
  if (customProductType) {
    return customProductServices[customProductType].getCustomProductPath(product, locale);
  }
  return productService.getCanonicalProductPath(product, locale);
}

function getPositionImgArray(product, locale, customProductType, intl) {
  if (!customProductType) {
    return [{
      src: productService.getImgPath(product),
      alt: product.name[locale],
      href: productService.getCanonicalProductPath(product, locale),
    }];
  }
  return customProductServices[customProductType].getPositionImgArray(product, locale, intl);
}

function getPositionUnitOfQuantity(product, locale, customProductType) {
  return productService.getAttributeValueTranslation(
    (customProductType)
      ? customProductServices[customProductType].getUnitOfQuantityI18nId()
      : product.unitOfQuantity.i18nId,
    undefined,
    locale
  );
}

function isValidOrderQuantity(orderQuantity, product, customProductType, customOrderQuantityInfo) {
  return productService.isValidOrderQuantity(
    orderQuantity, (customProductType) ? undefined : product, customOrderQuantityInfo
  );
}

function getOrderQuantityInfo(product, customProductType) {
  return productService.getOrderQuantityInfo((customProductType) ? undefined : product);
}

function unifyDecimalInput(value, locale) {
  let unifiedValue = value.replaceAll(
    numberHelper.getThousandSeparatorByLocale(locale), ''
  ).replaceAll(',', '.').replaceAll('..', '.');
  if (unifiedValue[unifiedValue.length - 1] === '.') {
    unifiedValue = `${unifiedValue}0`;
  }
  return unifiedValue;
}

function unifyInputIfDecimal(product, customProductType, value, locale) {
  if (getOrderQuantityInfo(product, customProductType).hasDecimalOrderQuantity) {
    return unifyDecimalInput(value, locale);
  }
  return value;
}

function formatPrice(price, locale) {
  return salesHelper.formatPrice(price, locale, getSalesConfig().currency);
}

function formatPercentage(percentage, locale) {
  return salesHelper.formatPercentage(percentage, locale);
}

function getPositionPriceInfo(product, orderQuantity, dataStorage, locale, customProductType) {
  if (customProductType) {
    return customProductServices[customProductType].getPositionPriceInfo(
      product.customConfiguration, orderQuantity, dataStorage, locale
    );
  }
  return productService.getPositionPriceInfo(product, orderQuantity, dataStorage, locale);
}

function getPositionsWithProducts(dataStorage) {
  if (dataStorage.cart.positions === undefined) {
    return undefined;
  }
  return dataStorage.cart.positions.map((position) => ({
    qty: position.qty,
    customProductType: position.customProductType,
    product: (position.customConfiguration) ? {
      id: position.productId,
      customConfiguration: customProductServices[position.customProductType]
        .getDeepConfigurationObject(
          position.customConfiguration, dataStorage
        ),
    } : productService.getProduct(position.productId, dataStorage),
  }));
}

function getOrderPositions(dataStorage, locale, intl) {
  const positions = getPositionsWithProducts(dataStorage);
  if (!positions) {
    return undefined;
  }
  return positions.map((position) => {
    const positionPriceInfo = getPositionPriceInfo(
      position.product, position.qty, dataStorage, locale, position.customProductType
    );
    position.discount = positionPriceInfo.discount;
    position.price = positionPriceInfo.price;
    position.productName = getPositionTitle(
      position.product, locale, intl, position.customProductType
    );
    position.translatedQty = `${position.qty} ${getPositionUnitOfQuantity(position.product, locale, position.customProductType)}`;
    position.priceLocaleString = formatPrice(position.price, locale);
    if (position.discount) {
      position.discountLocaleString = formatPercentage(position.discount, locale);
    }
    return position;
  });
}

function getTotalWeightOfCart(dataStorage) {
  const positions = getPositionsWithProducts(dataStorage);
  if (!positions) {
    return -1;
  }
  let totalWeight = 0;
  positions.forEach((position) => {
    totalWeight += (position.customProductType)
      ? customProductServices[position.customProductType].getTotalWeight(
        position.product.customConfiguration
      ) * position.qty
      : position.product.weight * position.qty || 0;
  });
  return totalWeight;
}

function addProductAndOrderQuantity(productAndOrderQuantities, product, orderQuantity) {
  if (Object.keys(productAndOrderQuantities).includes(product.id)) {
    productAndOrderQuantities[product.id].qty += orderQuantity;
  } else {
    productAndOrderQuantities[product.id] = {
      product,
      qty: orderQuantity,
    };
  }
  return productAndOrderQuantities;
}

function getProcurementTime(productAndOrderQuantities) {
  let procurementTime = 0;
  Object.keys(productAndOrderQuantities).forEach((productId) => {
    if (
      numberHelper.canBeConvertedToNumber(
        productAndOrderQuantities[productId].product.procurementTime
      ) && productAndOrderQuantities[productId].product.quantityInStock !== undefined
      && productAndOrderQuantities[productId].qty > productAndOrderQuantities[productId]
        .product.quantityInStock
      && Number(productAndOrderQuantities[productId].product.procurementTime) > procurementTime) {
      procurementTime = productAndOrderQuantities[productId].product.procurementTime;
    }
  });
  return procurementTime;
}

function getCartProcurementTime(dataStorage) {
  const positions = getPositionsWithProducts(dataStorage);
  let productAndOrderQuantities = {};
  positions.forEach((position) => {
    if (position.customProductType) {
      productAndOrderQuantities = customProductServices[position.customProductType]
        .addProductOrderQuantities(
          position.product.customConfiguration,
          productAndOrderQuantities,
          position.qty,
          addProductAndOrderQuantity
        );
    } else {
      productAndOrderQuantities = addProductAndOrderQuantity(
        productAndOrderQuantities, position.product, position.qty
      );
    }
  });
  return getProcurementTime(productAndOrderQuantities);
}

function countryHasNoDeliveryRestrictions(countryCode) {
  const salesConfig = getSalesConfig().orderConstraints.countries;
  if (!salesConfig.orderConstraints) {
    return true;
  }
  const countryRestrictions = salesConfig.orderConstraints.countries;
  if (countryRestrictions.include) {
    return countryRestrictions.include.includes(countryCode);
  }
  if (countryRestrictions.exclude) {
    return !countryRestrictions.exclude.includes(countryCode);
  }
  return true;
}

function isOrderedBeforeCertainHour(hour) {
  const currentHour = dateHelper.convertTimeZone(
    new Date(),
    'en',
    getSalesConfig().timeZone
  ).getHours();
  return currentHour < hour;
}

function paymentMethodIsAvaiable(paymentMethod, countryCode, shoppingCartValue) {
  if (paymentMethod.conditions && paymentMethod.conditions.length > 0) {
    for (let i = 0; i < paymentMethod.conditions.length; i++) {
      let match = true;
      if (paymentMethod.conditions[i].countries
        && !paymentMethod.conditions[i].countries.includes(countryCode)) {
        match = false;
      }
      if (paymentMethod.conditions[i].priceTo
        && paymentMethod.conditions[i].priceTo < shoppingCartValue) {
        match = false;
      }
      if (match) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function shippingMethodIsAvaiable(shippingMethod, countryCode, weight, procurementTime) {
  if (shippingMethod.conditions && shippingMethod.conditions.length > 0) {
    for (let i = 0; i < shippingMethod.conditions.length; i++) {
      let match = true;
      if (shippingMethod.conditions[i].countries
        && !shippingMethod.conditions[i].countries.includes(countryCode)) {
        match = false;
      }
      if (shippingMethod.conditions[i].allInStock
        && procurementTime > 0) {
        match = false;
      }
      if (shippingMethod.conditions[i].untilTime
        && !isOrderedBeforeCertainHour(shippingMethod.conditions[i].untilTime)) {
        match = false;
      }
      if (shippingMethod.conditions[i].weight
        && shippingMethod.conditions[i].weight.from
        && shippingMethod.conditions[i].weight.from > weight) {
        match = false;
      }
      if (shippingMethod.conditions[i].weight
        && shippingMethod.conditions[i].weight.to
        && shippingMethod.conditions[i].weight.to <= weight) {
        match = false;
      }
      if (match) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function getDeliveryTimeObject(deliveryTimeObjects, countryCode) {
  for (let i = 0; i < deliveryTimeObjects.length; i++) {
    if (!deliveryTimeObjects[i].countries
      || deliveryTimeObjects[i].countries.includes(countryCode)
    ) {
      return deliveryTimeObjects[i];
    }
  }
  return undefined;
}

function getDeliveryTimeInfo(shippingMethod, locale, countryCode, procurementTime, intl) {
  if (shippingMethod.msg) {
    return { message: shippingMethod.msg[locale] };
  }
  const deliveryTimeObject = getDeliveryTimeObject(shippingMethod.deliveryTimes, countryCode);
  const daysToAdd = isOrderedBeforeCertainHour(getSalesConfig().sameDayShippingUntil)
    ? Number(procurementTime) : Number(procurementTime) + 1;
  let param1;
  let param2;
  const { publicHolidays } = getSalesConfig();
  if (deliveryTimeObject.days.length === 1 && deliveryTimeObject.time) {
    param1 = { id: 'deliveryBeforeX' };
    param2 = {
      date: `${dateHelper.getLocalWorkingDatePlusCalendarDays(
        Number(deliveryTimeObject.days[0]) + daysToAdd, locale, publicHolidays
      )} ${deliveryTimeObject.time}`,
    };
  } else if (deliveryTimeObject.days.length === 1) {
    param1 = { id: 'deliveryOnX' };
    param2 = {
      date: dateHelper.getLocalWorkingDatePlusCalendarDays(
        Number(deliveryTimeObject.days[0]) + daysToAdd, locale, publicHolidays
      ),
    };
  } else {
    const dateFrom = dateHelper.getWorkingDatePlusCalendarDays(
      Number(deliveryTimeObject.days[0]) + daysToAdd, publicHolidays
    );
    param1 = { id: 'deliveryBetweenX' };
    param2 = {
      dateFrom: dateHelper.getLocalWorkingDatePlusCalendarDays(
        Number(deliveryTimeObject.days[0]) + daysToAdd, locale, publicHolidays
      ),
      dateTo: dateHelper.getLocalWorkingDatePlusCalendarDays(
        Number(deliveryTimeObject.days[1]) - Number(deliveryTimeObject.days[0]),
        locale,
        publicHolidays,
        dateFrom
      ),
    };
  }
  return {
    translatedMessage: intl.formatMessage(param1, param2),
    param1,
    param2,
  };
}

function getDeliveryTimeText(deliveryTimeInfo, intl) {
  return (deliveryTimeInfo.message)
    ? deliveryTimeInfo.message
    : intl.formatMessage(deliveryTimeInfo.param1, deliveryTimeInfo.param2);
}

function getIncotermInfo(incoterm, shoppingCartValue) {
  if (!incoterm) {
    return { valid: false };
  }
  if (incoterm.length > 3) {
    return {
      label: incoterm.substr(0, 3),
      valid: shoppingCartValue >= incoterm.substr(3),
    };
  }
  return {
    label: incoterm,
    valid: shoppingCartValue >= 0,
  };
}

function getIncoterm(defaultIncoterm, customerIncoterm, shoppingCartValue) {
  const customerIncotermInfo = getIncotermInfo(customerIncoterm, shoppingCartValue);
  if (customerIncotermInfo.valid) {
    return customerIncotermInfo.label;
  }
  const defaultIncotermInfo = getIncotermInfo(defaultIncoterm, shoppingCartValue);
  if (defaultIncotermInfo.valid) {
    return defaultIncotermInfo.label;
  }
  return 'EXW';
}

function getShippingMethodCosts(
  shippingMethod, countryCode, weight, defaultIncoterm, customerIncoterm, shoppingCartValue
) {
  if (shippingMethod.checkCustomerIncoterms
    && getIncoterm(defaultIncoterm, customerIncoterm, shoppingCartValue) !== 'EXW'
  ) {
    return 0;
  }
  if (shippingMethod.priceRules && shippingMethod.priceRules.length > 0) {
    for (let i = 0; i < shippingMethod.priceRules.length; i++) {
      let match = true;
      if (shippingMethod.priceRules[i].countries
        && !shippingMethod.priceRules[i].countries.includes(countryCode)) {
        match = false;
      }
      if (shippingMethod.priceRules[i].weight
        && shippingMethod.priceRules[i].weight.from
        && shippingMethod.priceRules[i].weight.from > weight) {
        match = false;
      }
      if (shippingMethod.priceRules[i].weight
        && shippingMethod.priceRules[i].weight.to
        && shippingMethod.priceRules[i].weight.to <= weight) {
        match = false;
      }
      if (match) {
        return shippingMethod.priceRules[i].price;
      }
    }
  }
  return shippingMethod.defaultPrice;
}

function getShippingMethods(
  shoppingCartValue, customerIncoterm, countryCode, dataStorage, locale, intl
) {
  const shippingMethods = [];
  if (countryCode && countryHasNoDeliveryRestrictions(countryCode)) {
    const totalWeight = getTotalWeightOfCart(dataStorage);
    if (totalWeight >= 0) {
      const procurementTime = getCartProcurementTime(dataStorage);
      getSalesConfig().shippingMethods.forEach((shippingMethod) => {
        if (shippingMethodIsAvaiable(
          shippingMethod, countryCode, totalWeight, procurementTime
        )) {
          shippingMethod.price = getShippingMethodCosts(
            shippingMethod, countryCode, totalWeight,
            getSalesConfig().defaultIncoterm, customerIncoterm, shoppingCartValue
          );
          shippingMethod.procurementTime = procurementTime;
          shippingMethod.deliveryTimeInfo = getDeliveryTimeInfo(
            shippingMethod, locale, countryCode, procurementTime, intl
          );
          shippingMethods.push(shippingMethod);
        }
      });
    }
  }
  return shippingMethods;
}

function getAdditionalOrderCostParams(shippingMethod, destinationCountry, paymentMethod) {
  if (shippingMethod) {
    return {
      destinationCountry,
      shippingCosts: shippingMethod.price,
      paymentMethod,
    };
  }
  return undefined;
}

function isValidCartPriceRule(priceRule, shoppingCartValue, customerPricingGroup) {
  if (salesHelper.isValidPriceRule(priceRule, customerPricingGroup)
    && !priceRule.productId && !priceRule.productTypeI18nId && !priceRule.productPricingGroup
    && priceRule.shoppingCartValueFrom !== undefined
    && priceRule.shoppingCartValueFrom <= shoppingCartValue
    && (priceRule.netPrice !== undefined || priceRule.percentage !== undefined)
    && (priceRule.percentage < 0 || priceRule.netPrice < 0)
  ) {
    return true;
  }
  return false;
}

function getMoreImportantCartPriceRule(ruleA, ruleB) {
  if (!ruleA) {
    return ruleB;
  }
  if (ruleA.priority > ruleB.priority) {
    return ruleA;
  }
  if (ruleA.priority < ruleB.priority) {
    return ruleB;
  }
  if (ruleA.shoppingCartValueFrom < ruleB.shoppingCartValueFrom) {
    return ruleB;
  }
  if (ruleA.shoppingCartValueFrom > ruleB.shoppingCartValueFrom) {
    return ruleA;
  }
  return ruleA;
}

function getCartPriceRule(shoppingCartValue, customerPricingGroup, currentPriceRule, priceRules) {
  let newPriceRule = currentPriceRule;
  priceRules.forEach((priceRule) => {
    if (isValidCartPriceRule(
      priceRule, shoppingCartValue, customerPricingGroup
    )) {
      newPriceRule = getMoreImportantCartPriceRule(newPriceRule, priceRule);
    }
  });
  return newPriceRule;
}

function getCartPriceRuleDiscount(shoppingCartValue, dataStorage) {
  let priceRule;
  if (dataStorage.user.firstname) {
    priceRule = getCartPriceRule(
      shoppingCartValue, dataStorage.user.pricingGroup, priceRule, dataStorage.user.customPricing
    );
    priceRule = getCartPriceRule(
      shoppingCartValue, dataStorage.user.pricingGroup, priceRule, dataStorage.groupPriceRules
    );
  }
  priceRule = getCartPriceRule(
    shoppingCartValue, undefined, priceRule, dataStorage.defaultPriceRules
  );
  if (priceRule) {
    if (priceRule.percentage) {
      return {
        discountPercentage: priceRule.percentage,
        discountAmount: shoppingCartValue * (priceRule.percentage / 100),
      };
    }
    return { discountAmount: priceRule.netPrice };
  }
  return undefined;
}

function getVatRate(destinationCountry) {
  return getSalesConfig().vat[destinationCountry] || 0;
}

function getCartPriceInfo(
  positions, additionalOrderCostParams, couponRule, locale, dataStorage, intl
) {
  let itemTotal = 0;
  if (positions) {
    positions.forEach((position) => {
      itemTotal += getPositionPriceInfo(
        position.product, position.qty, dataStorage, locale, position.customProductType
      ).price;
    });
  }
  let discountAmount;
  let discountPercentage;
  let couponCode;
  const priceRuleDiscountInfo = getCartPriceRuleDiscount(itemTotal, dataStorage);
  if (priceRuleDiscountInfo) {
    ({ discountAmount, discountPercentage } = priceRuleDiscountInfo);
  }
  if (couponRule) {
    couponCode = couponRule.couponCode;
    if (couponRule.discountType === 'percentage') {
      discountAmount = itemTotal * (couponRule.percentage / 100);
      discountPercentage = couponRule.discount;
    } else {
      discountAmount = couponRule.discount;
    }
  }
  return salesHelper.getCartPriceInfoObject({
    itemTotal,
    discountAmount,
    discountPercentage,
    couponCode,
    shippingCosts: (additionalOrderCostParams && additionalOrderCostParams.shippingCosts)
      ? additionalOrderCostParams.shippingCosts : 0,
    paymentCosts: (additionalOrderCostParams && additionalOrderCostParams.paymentMethod)
      ? additionalOrderCostParams.paymentMethod.fee : 0,
    vatRate: getVatRate((additionalOrderCostParams)
      ? additionalOrderCostParams.destinationCountry : undefined),
  }, locale, getSalesConfig(), intl);
}

function getPaymentMethods(countryCode, shoppingCartValue) {
  const paymentMethods = [];
  getSalesConfig().paymentMethods.forEach((paymentMethod) => {
    if (paymentMethodIsAvaiable(paymentMethod, countryCode, shoppingCartValue)) {
      paymentMethods.push(paymentMethod);
    }
  });
  return paymentMethods;
}

function getMinimalOrderValue() {
  return getSalesConfig().orderConstraints.minimalOrderValue;
}

function getFormattedMinimalOrderValue(locale) {
  return formatPrice(getSalesConfig().orderConstraints.minimalOrderValue, locale);
}

function getNumberOfValidOrders(orders) {
  let validOrders = 0;
  orders.forEach((order) => {
    if (order.status !== 'canceled') {
      validOrders += 1;
    }
  });
  return validOrders;
}

function getSummedAndFormattedSubtotal(orders, locale) {
  let summedSubtotal = 0;
  orders.forEach((order) => {
    if (order.status !== 'canceled') {
      summedSubtotal += order.price.subtotal;
    }
  });
  return formatPrice(summedSubtotal, locale);
}

export default {
  setDependencies,
  importCart,
  reorderCart,
  getTermsAndConditionsPath,
  formatPrice,
  formatPercentage,
  getDefaultCheckoutSteps,
  getOrderByUrlQuery,
  getFormattedOrderDate,
  getPositionTitle,
  getPositionLink,
  getPositionImgArray,
  getPositionUnitOfQuantity,
  isValidOrderQuantity,
  getOrderQuantityInfo,
  unifyDecimalInput,
  unifyInputIfDecimal,
  getPositionPriceInfo,
  getPositionsWithProducts,
  getOrderPositions,
  getTotalWeightOfCart,
  getDeliveryTimeText,
  getShippingMethods,
  getPaymentMethods,
  getAdditionalOrderCostParams,
  getCartPriceInfo,
  getMinimalOrderValue,
  getFormattedMinimalOrderValue,
  getVatRate,
  getProcurementTime,
  getCartProcurementTime,
  getNumberOfValidOrders,
  getSummedAndFormattedSubtotal,
};
