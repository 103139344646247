import arrayHelper from '../utils/helper/array-helper';
import objectHelper from '../utils/helper/object-helper';
import urlHelper from '../utils/helper/url-helper';
import salesHelper from '../utils/helper/sales-helper';
import numberHelper from '../utils/helper/number-helper';

let configService;
let apiService;
let listService;
let attrValueNullDetected = false;
let translIdUndefAlreadyDetected = false;

function setDependencies(dependencies) {
  ({ configService, apiService, listService } = dependencies);
  return this;
}

function getProductConfig() {
  return configService.getGeneralConfig().product;
}

function getProductCategoryConfig() {
  return configService.getGeneralConfig().product.category;
}

function getProductCadConfig() {
  return getProductConfig().cad;
}

function getPartialQuantityConfig() {
  return configService.getGeneralConfig().sales.partialQuantity;
}

function getCurrency() {
  return configService.getGeneralConfig().sales.currency;
}

function getProductTypeConfigById(productTypeId) {
  return getProductConfig().productTypes[productTypeId] || {};
}

function getProductTypeConfigByProduct(product) {
  return getProductConfig().productTypes[product.productType.i18nId] || {};
}

function getOrderQuantityTypeByProduct(product) {
  if (product.productType) {
    const productTypeConfig = getProductConfig().productTypes[product.productType.i18nId];
    if (productTypeConfig && productTypeConfig.orderQuantity) {
      return productTypeConfig.orderQuantity.dataType;
    }
  }
  return undefined;
}

function getProduct(id, dataStorage) {
  for (let i = 0; i < dataStorage.products.length; i++) {
    if (dataStorage.products[i].id === id) {
      return dataStorage.products[i];
    }
  }
  return undefined;
}

function getProductCategory(id, productCategories) {
  for (let i = 0; i < productCategories.length; i++) {
    if (productCategories[i].id === id) {
      return productCategories[i];
    }
  }
  return undefined;
}

function getProductCategoryBySlug(slug, productCategories, locale) {
  return urlHelper.getItemByUrlSlug(productCategories, slug, locale);
}

function getPageInfo(products, categories, locale) {
  const path = window.location.pathname;
  const slugs = path.split('/');
  slugs.shift();
  const productOrCategoryInfo = {
    completed: true,
    path,
    slugs,
  };
  if (slugs[0] === getProductConfig().canonical.slugs[locale]) {
    productOrCategoryInfo.product = urlHelper.getItemByUrlSlug(products, slugs[1], locale);
  } else if (slugs[0] === locale && slugs[1] === getProductConfig().canonical.slugs[locale]) {
    productOrCategoryInfo.product = urlHelper.getItemByUrlSlug(products, slugs[2], locale);
  } else {
    const webpage = configService.getPageByPath(path);
    if (webpage.path === path) {
      productOrCategoryInfo.webpage = webpage;
    } else {
      const category = urlHelper.getItemByUrlSlug(categories, slugs[slugs.length - 1], locale);
      if (category) {
        productOrCategoryInfo.productCategory = category;
      } else {
        productOrCategoryInfo.product = urlHelper.getItemByUrlSlug(
          products, slugs[slugs.length - 1], locale
        );
      }
    }
  }
  return productOrCategoryInfo;
}

function getLangVersionSlug(locale) {
  let langVersionSlug = '';
  if (configService.getGeneralConfig().i18n.defaultLocale !== locale) {
    langVersionSlug = `/${locale}`;
  }
  return langVersionSlug;
}

function getCanonicalPath(item, canonicalConfig, locale) {
  if (item.canonicalUrl && item.canonicalUrl[locale]) {
    return item.canonicalUrl[locale];
  }
  return `${getLangVersionSlug(locale)}/${canonicalConfig.slugs[locale]}/${urlHelper.getUrlSlug(item, locale)}`;
}

function getCanonicalProductPath(product, locale) {
  return getCanonicalPath(product, getProductConfig().canonical, locale);
}

function getCanonicalUrl(item, canonicalConfig, locale) {
  return configService.getUrl(getCanonicalPath(item, canonicalConfig, locale));
}

function getCanonicalProductUrl(product, locale) {
  return getCanonicalUrl(product, getProductConfig().canonical, locale);
}

function getProductHrefOnProductItem(product, locale) {
  const currentPathSlugs = window.location.pathname.split('/');
  return `${currentPathSlugs[currentPathSlugs.length - 1]}/${urlHelper.getUrlSlug(product, locale)}`;
}

function addCanonicalParentCategory(canonicalParentCategories, productCategory, productCategories) {
  const parentCategory = getProductCategory(
    productCategory.canonicalParentCategoryId, productCategories
  );
  canonicalParentCategories.unshift(parentCategory);
  if (parentCategory.canonicalParentCategoryId) {
    canonicalParentCategories = addCanonicalParentCategory(
      canonicalParentCategories,
      parentCategory,
      productCategories
    );
  }
  return canonicalParentCategories;
}

function getCanonicalProductCategoryPath(productCategory, productCategories, locale) {
  let categories = [];
  if (productCategory.canonicalParentCategoryId) {
    categories = addCanonicalParentCategory(categories, productCategory, productCategories);
  }
  categories.push(productCategory);
  let canoncialPath = '';
  categories.forEach((category) => {
    canoncialPath = `${canoncialPath}/${urlHelper.getUrlSlug(category, locale)}`;
  });
  return `${getLangVersionSlug(locale)}/${getProductConfig().category.canonical.slugs[locale]}${canoncialPath}`;
}

function getCanonicalProductCategoryUrl(productCategory, productCategories, locale) {
  return configService.getUrl(
    getCanonicalProductCategoryPath(productCategory, productCategories, locale)
  );
}

function getCanonicalProductCategoryPathById(productCategoryId, productCategories, locale) {
  return getCanonicalProductCategoryPath(
    getProductCategory(productCategoryId, productCategories), productCategories, locale
  );
}

function getTranslatedPagePath(
  currentPath,
  currentTemplatePath,
  targetTemplatePath,
  currentLocale,
  targetLocale,
  products,
  productCategories
) {
  const currentPathSlugs = currentPath.split('/');
  const currentPageSlugs = currentTemplatePath.split('/');
  const targetPageSlugs = targetTemplatePath.split('/');
  if (currentPathSlugs[currentPathSlugs.length - 1] === '') {
    currentPathSlugs.pop();
  }
  if (currentPageSlugs[currentPageSlugs.length - 1] === '') {
    currentPageSlugs.pop();
  }
  if (targetPageSlugs[targetPageSlugs.length - 1] === '') {
    targetPageSlugs.pop();
  }
  if (targetPageSlugs[targetPageSlugs.length - 1].startsWith(':')) {
    targetPageSlugs.pop();
  }
  for (let i = currentPageSlugs.length; i < currentPathSlugs.length; i++) {
    const categorySlug = urlHelper.translateSlug(
      currentPathSlugs[i], currentLocale, targetLocale, productCategories
    );
    if (categorySlug) {
      targetPageSlugs.push(categorySlug);
    } else if ((i + 1) === currentPathSlugs.length) {
      const productSlug = urlHelper.translateSlug(
        currentPathSlugs[i], currentLocale, targetLocale, products
      );
      if (productSlug) {
        targetPageSlugs.push(productSlug);
      } else {
        console.error(`getTranslatedPagePath | 1 | Current path:${currentPath} Target template path:${targetTemplatePath} currentLocale:${currentLocale} targetLocale:${targetLocale} i:${i}`);
      }
    } else {
      console.error(`getTranslatedPagePath | 2 | Current path:${currentPath} Target template path:${targetTemplatePath} currentLocale:${currentLocale} targetLocale:${targetLocale} i:${i}`);
    }
  }
  return targetPageSlugs.join('/');
}

function getTranslationOfBaseAttribute(attributeId, intl) {
  return intl.formatMessage({ id: `prdt${attributeId.charAt(0).toUpperCase()}${attributeId.slice(1)}` });
}

function getAdditionalAttributeMetaData(attributeId) {
  const additionalAttributesMetaData = getProductConfig().additionalAttributes;
  for (let i = 0; i < additionalAttributesMetaData.length; i++) {
    if (additionalAttributesMetaData[i].id === attributeId) {
      return additionalAttributesMetaData[i];
    }
  }
  return null;
}

function isAdditionalAttributeWithNoi18n(attributeId) {
  const metaData = getAdditionalAttributeMetaData(attributeId);
  if (metaData && metaData.i18nImpl !== 'id') {
    return true;
  }
  return false;
}

function getAttributeValueTranslation(
  translationId, i18nIdUndefined, locale, doNotSendExceptionIfUndef
) {
  if (!translationId && !i18nIdUndefined) {
    return undefined;
  }
  try {
    if (!i18nIdUndefined) {
      return getProductConfig().i18n[translationId][locale];
    }
    return (translationId)
      ? getProductConfig().i18n[translationId][locale]
      : getProductConfig().i18n[i18nIdUndefined][locale];
  } catch (err) {
    if (!doNotSendExceptionIfUndef && translIdUndefAlreadyDetected === false) {
      const errInfo = {
        translationId, i18nIdUndefined, locale, method: 'getAttributeValueTranslation',
      };
      apiService.post('/system/frontend-error', {
        errMsg: 'translationId undefined',
        errInfo,
      });
      console.error(errInfo);
    }
    translIdUndefAlreadyDetected = true;
    return undefined;
  }
}

function getTranslationOfBaseAttributeMeasuringUnit(attributeId, locale, intl) {
  if (attributeId === 'weight') {
    return getAttributeValueTranslation(
      getProductConfig().baseAttributes.defaultWeightUnitI18nId, undefined, locale
    );
  } else if (attributeId === 'price' || attributeId === 'productType') {
    return undefined;
  }
  const translation = intl.formatMessage({ id: `prdtMU${attributeId.charAt(0).toUpperCase()}${attributeId.slice(1)}` });
  return (translation.includes('prdtMU')) ? undefined : translation;
}

function geti18nIdTranslation(value, locale, i18nIdUndefined) {
  if (Array.isArray(value)) {
    return value.map((item) => getAttributeValueTranslation(item, i18nIdUndefined, locale));
  }
  return getAttributeValueTranslation(value, i18nIdUndefined, locale);
}

function calculatePriceForPartialQuantity(
  listPrice, priceUnit, packagingUnit, orderQuantity, partialQuantityConfig
) {
  /* console.log('calculatePriceForPartialQuantity');
  console.log(`listPrice: ${listPrice}`);
  console.log(`priceUnit: ${priceUnit}`);
  console.log(`packagingUnit: ${packagingUnit}`);
  console.log(`orderQuantity: ${orderQuantity}`); */
  const ratio = orderQuantity / packagingUnit;
  // console.log(`ratio: ${ratio}`);
  let newUnitPrice = listPrice / priceUnit;
  // console.log(`newUnitPrice: ${newUnitPrice}`);
  if (!partialQuantityConfig) {
    return newUnitPrice * orderQuantity;
  }
  if (partialQuantityConfig.surcharges) {
    let surchargeMatch = false;
    for (let i = 0; i < partialQuantityConfig.surcharges.length && !surchargeMatch; i++) {
      const surcharge = partialQuantityConfig.surcharges[i];
      if ((!surcharge.ratioFrom || surcharge.ratioFrom <= ratio)
        && (!surcharge.ratioTo || surcharge.ratioTo > ratio)
      ) {
        newUnitPrice *= surcharge.multiplier;
        surchargeMatch = true;
      }
    }
  }
  // console.log(`newUnitPrice after ratio calc: ${newUnitPrice}`);

  if (partialQuantityConfig.discardCalcFromAmountOf <= (newUnitPrice * orderQuantity)) {
    newUnitPrice = listPrice / priceUnit;
  }
  // console.log(`newUnitPrice after discardCalcFromAmountOf: ${newUnitPrice}`);

  if (partialQuantityConfig.minimumPositionPrice
    && partialQuantityConfig.minimumPositionPrice > (newUnitPrice * orderQuantity)
  ) {
    newUnitPrice = partialQuantityConfig.minimumPositionPrice / orderQuantity;
  }
  // console.log(`newUnitPrice after minimumPositionPrice: ${newUnitPrice}`);

  return newUnitPrice * orderQuantity;
}

function isValidPositionPriceRule(priceRule, product, orderQuantity, customerPricingGroup) {
  if (salesHelper.isValidPriceRule(priceRule, customerPricingGroup)
    && (!priceRule.productId || priceRule.productId === product.id)
    && (!priceRule.productTypeI18nId || priceRule.productTypeI18nId === product.productType.i18nId)
    && (!priceRule.productPricingGroup || priceRule.productPricingGroup === product.pricingGroup)
    && !priceRule.shoppingCartValueFrom
    && (!priceRule.quantityFrom || priceRule.quantityFrom <= orderQuantity)
  ) {
    return true;
  }
  return false;
}

function getMoreImportantPositionPriceRule(ruleA, ruleB) {
  const optionalProperties = ['productId', 'productTypeI18nId', 'productPricingGroup'];
  if (!ruleA) {
    return ruleB;
  }
  if (ruleA.priority > ruleB.priority) {
    return ruleA;
  }
  if (ruleA.priority < ruleB.priority) {
    return ruleB;
  }
  for (let i = 0; i < optionalProperties.length; i++) {
    const rule = salesHelper.getMoreImportantPositionPriceRuleByOptionalProperty(
      ruleA, ruleB, optionalProperties[i]
    );
    if (rule) {
      return rule;
    }
  }
  if (ruleA.quantityFrom < ruleB.quantityFrom) {
    return ruleB;
  }
  if (ruleA.quantityFrom > ruleB.quantityFrom) {
    return ruleA;
  }
  return ruleA;
}

function getPositionPriceRule(product, orderQuantity, pricingGroup, currentPriceRule, priceRules) {
  let newPriceRule = currentPriceRule;
  priceRules.forEach((priceRule) => {
    if (isValidPositionPriceRule(
      priceRule, product, orderQuantity, pricingGroup
    )) {
      newPriceRule = getMoreImportantPositionPriceRule(newPriceRule, priceRule);
    }
  });
  return newPriceRule;
}

function getPositionPriceInfoObject(locale, price, discount, listPrice) {
  return salesHelper.getPositionPriceInfoObject(locale, getCurrency(), price, discount, listPrice);
}

function getPositionPriceInfo(product, orderQuantity, dataStorage, locale) {
  if (orderQuantity <= 0) {
    return getPositionPriceInfoObject(locale, 0);
  }
  const partialQuantityConfig = getPartialQuantityConfig();
  const defaultPositionPrice = (product.price / product.priceUnit) * orderQuantity;
  let priceRule;
  if (product.packagingUnit > orderQuantity
    && !partialQuantityConfig.excludedProductTypes.includes(product.productType.i18nId)) {
    return getPositionPriceInfoObject(locale, calculatePriceForPartialQuantity(
      product.price, product.priceUnit, product.packagingUnit, orderQuantity, partialQuantityConfig
    ));
  }
  if (product.packagingUnit <= orderQuantity && dataStorage.user && dataStorage.user.firstname) {
    priceRule = getPositionPriceRule(
      product, orderQuantity, dataStorage.user.pricingGroup,
      priceRule, dataStorage.user.customPricing
    );
    priceRule = getPositionPriceRule(
      product, orderQuantity, dataStorage.user.pricingGroup,
      priceRule, dataStorage.groupPriceRules
    );
  }
  priceRule = getPositionPriceRule(
    product, orderQuantity, undefined,
    priceRule, dataStorage.defaultPriceRules
  );
  if (priceRule) {
    if (priceRule.netPrice) {
      const defaultNetPositionPrice = (priceRule.netPrice / (priceRule.priceUnit
        || product.priceUnit)) * orderQuantity;
      if (priceRule.netPrice >= product.price) {
        return getPositionPriceInfoObject(locale, defaultNetPositionPrice);
      }
      return getPositionPriceInfoObject(
        locale, defaultNetPositionPrice, (priceRule.netPrice / product.price - 1) * 100,
        defaultPositionPrice
      );
    }
    if (priceRule.percentage) {
      if (priceRule.percentage >= 0) {
        return getPositionPriceInfoObject(
          locale, defaultPositionPrice * (priceRule.percentage / 100 + 1)
        );
      }
      return getPositionPriceInfoObject(
        locale, defaultPositionPrice * (priceRule.percentage / 100 + 1), priceRule.percentage * 1,
        defaultPositionPrice
      );
    }
  }
  return getPositionPriceInfoObject(locale, defaultPositionPrice);
}

function getDefaultOrderQuantity(product) {
  const productTypeConfig = getProductTypeConfigByProduct(product);
  let defaultQuantity;
  if (productTypeConfig && productTypeConfig.orderQuantity) {
    defaultQuantity = productTypeConfig.orderQuantity.default;
  }
  if (defaultQuantity) {
    return defaultQuantity;
  } else if (product.packagingUnit) {
    return product.packagingUnit;
  }
  return 1;
}

function getProductNetPrice(product, locale, dataStorage, qty) {
  return getPositionPriceInfo(
    product, qty || getDefaultOrderQuantity(product), dataStorage, locale
  ).price;
}

function getBaseAttributeValue(product, attributeId, locale, dataStorage) {
  if (typeof product[attributeId] === 'object') {
    return product[attributeId][locale]
      || geti18nIdTranslation(product[attributeId].i18nId, locale);
  } else if (attributeId !== 'price' && (typeof product[attributeId] === 'number') && (product[attributeId] % 1 !== 0)) {
    return product[attributeId].toLocaleString(locale);
  } else if (attributeId === 'price') {
    return getProductNetPrice(product, locale, dataStorage);
  }
  return product[attributeId];
}

function getAdditionalAttributeValue(product, attributeId, i18nImpl, i18nIdUndefined, locale) {
  if (i18nImpl === 'id') {
    const value = (product.additionalAttributes.i18nId)
      ? product.additionalAttributes.i18nId[attributeId]
      : undefined;
    return geti18nIdTranslation(value, locale, i18nIdUndefined);
  } else if (i18nImpl === 'locale') {
    return product.additionalAttributes[locale][attributeId];
  }
  if (!i18nIdUndefined) {
    return product.additionalAttributes.noI18n[attributeId];
  }
  return product.additionalAttributes.noI18n[attributeId]
    || getProductConfig().i18n[i18nIdUndefined][locale];
}

function getAttributeValue(product, attributeId, locale) {
  const additionalAttrMetaData = getAdditionalAttributeMetaData(attributeId);
  if (additionalAttrMetaData) {
    return getAdditionalAttributeValue(
      product,
      attributeId,
      additionalAttrMetaData.i18nImpl,
      additionalAttrMetaData.i18nIdUndefined,
      locale
    );
  }
  return getBaseAttributeValue(product, attributeId, locale);
}

function getAdditionalAttributeProperty(attributeId, property, locale) {
  const additionalAttrMetaData = getAdditionalAttributeMetaData(
    attributeId
  );
  if (additionalAttrMetaData && additionalAttrMetaData[property]) {
    return additionalAttrMetaData[property][locale];
  }
  return undefined;
}

function getAdditionalAttributeName(attributeId, locale) {
  return getAdditionalAttributeProperty(attributeId, 'names', locale);
}

function getAdditionalAttributeMeasuringUnit(attributeId, locale) {
  return getAdditionalAttributeProperty(attributeId, 'measuringUnit', locale);
}

function getMeasuringUnitTranslation(attributeId, locale, intl) {
  const additionalAttributeMetaData = getAdditionalAttributeMetaData(attributeId);
  if (additionalAttributeMetaData) {
    return getAdditionalAttributeMeasuringUnit(attributeId, locale);
  }
  return getTranslationOfBaseAttributeMeasuringUnit(attributeId, locale, intl);
}

function getFormattedQuantityAndUnit(quantity, product, locale) {
  return `${Number(quantity).toLocaleString(locale)} ${getAttributeValueTranslation(
    product.unitOfQuantity.i18nId, undefined, locale
  )}`;
}

function getFilterAttributes(productCategory) {
  return getProductConfig().filters[productCategory] || getProductConfig().filters.default;
}

function getFilterAttributesForSearchPage(products, maxNumberSearchResults) {
  if (!products || products.length < 5 || products.length >= maxNumberSearchResults) {
    return getFilterAttributes('default');
  }
  const productTypeI18nIds = [];
  const filterAttributeIds = [];
  products.forEach((product) => {
    if (!productTypeI18nIds.includes(product.productType.i18nId)) {
      productTypeI18nIds.push(product.productType.i18nId);
    }
    if (objectHelper.isPopulatedObject(product.additionalAttributes.i18nId)) {
      Object.keys(product.additionalAttributes.i18nId).forEach((attributeId) => {
        if (!filterAttributeIds.includes(attributeId)) {
          filterAttributeIds.push(attributeId);
        }
      });
    }
    if (objectHelper.isPopulatedObject(product.additionalAttributes.noI18n)) {
      Object.keys(product.additionalAttributes.noI18n).forEach((attributeId) => {
        if (!filterAttributeIds.includes(attributeId)) {
          filterAttributeIds.push(attributeId);
        }
      });
    }
  });
  if (productTypeI18nIds.length === 1) {
    if (getProductConfig().filters[productTypeI18nIds[0]]) {
      return getProductConfig().filters[productTypeI18nIds[0]];
    }
  } else {
    filterAttributeIds.unshift('productType');
  }
  if (filterAttributeIds.length > 50) {
    console.log(`getFilterAttributesForSearchPage | Anzahl filterAttributeIds: ${filterAttributeIds.length}`);
    return getFilterAttributes('default');
  }
  return filterAttributeIds;
}

function buildFilterAttributeIds(prefilters) {
  if (!prefilters.productType) {
    return [];
  }
  const filterAttributeIds = [];
  prefilters.productType.forEach((productType) => {
    getFilterAttributes(productType).forEach((filter) => {
      if (!filterAttributeIds.includes(filter)
        && (!prefilters[filter] || prefilters[filter].length > 1)) {
        filterAttributeIds.push(filter);
      }
    });
  });
  if (!filterAttributeIds.includes('productType') && (!prefilters.productType || prefilters.productType.length > 1)) {
    filterAttributeIds.push('productType');
  }
  return filterAttributeIds;
}

function getProductAttributeValuesFunc(product, filterAttributeId, locale, dataStorage) {
  const additionalAttributeMetaData = getAdditionalAttributeMetaData(filterAttributeId);
  if (additionalAttributeMetaData) {
    return getAdditionalAttributeValue(
      product,
      filterAttributeId,
      additionalAttributeMetaData.i18nImpl,
      additionalAttributeMetaData.i18nIdUndefined,
      locale
    );
  }
  return getBaseAttributeValue(product, filterAttributeId, locale, dataStorage);
}

function getFilteredProductList(products, activeFilters, locale, dataStorage) {
  return listService.getFilteredItemList(
    products, activeFilters, locale, dataStorage, getProductAttributeValuesFunc
  );
}

function getProductsForCategory(products, categoryFilters, locale) {
  const filters = {};
  Object.keys(categoryFilters).forEach((attributeId) => {
    if (Array.isArray(categoryFilters[attributeId])) {
      if (isAdditionalAttributeWithNoi18n(attributeId)) {
        filters[attributeId] = categoryFilters[attributeId];
      } else {
        filters[attributeId] = categoryFilters[attributeId].map(
          (i18nId) => getAttributeValueTranslation(i18nId, undefined, locale)
        );
      }
    } else {
      filters[attributeId] = categoryFilters[attributeId];
    }
  });
  return getFilteredProductList(products, filters, locale);
}

function getFilterListMetaData(
  products, filterAttributeIds, selectedValues, locale, dataStorage, intl
) {
  const filterdProducts = getFilteredProductList(products, selectedValues, locale, dataStorage);
  return filterAttributeIds.map((attributeId) => {
    const additionalAttrMetaData = getAdditionalAttributeMetaData(
      attributeId
    );
    let heading;
    let values;
    let originalValues;
    let type = 'multiSelect';
    let currency;
    let hasFromToValues = false;
    if (additionalAttrMetaData) {
      heading = additionalAttrMetaData.names[locale];
      values = arrayHelper.concatInnerArray(filterdProducts.map((product) => (
        getAdditionalAttributeValue(
          product,
          attributeId,
          additionalAttrMetaData.i18nImpl,
          additionalAttrMetaData.i18nIdUndefined,
          locale
        )
      )));
      originalValues = arrayHelper.concatInnerArray(products.map((product) => (
        getAdditionalAttributeValue(
          product,
          attributeId,
          additionalAttrMetaData.i18nImpl,
          additionalAttrMetaData.i18nIdUndefined,
          locale
        )
      )));
      if (additionalAttrMetaData.dataType === 'MinMax') {
        type = 'range';
        hasFromToValues = true;
      } else if (additionalAttrMetaData.filterType === 'RangeSlider') {
        type = 'range';
      }
    } else {
      heading = getTranslationOfBaseAttribute(attributeId, intl);
      values = arrayHelper.concatInnerArray(filterdProducts.map((product) => (
        getBaseAttributeValue(
          product,
          attributeId,
          locale,
          dataStorage
        )
      )));
      originalValues = arrayHelper.concatInnerArray(products.map((product) => (
        getBaseAttributeValue(
          product,
          attributeId,
          locale,
          dataStorage
        )
      )));
      if (attributeId === 'price') {
        type = 'range';
        currency = configService.getGeneralConfig().sales.currency;
      }
    }
    return {
      type,
      id: attributeId,
      heading,
      values,
      selectedValues: selectedValues[attributeId],
      filteredOutValues: originalValues.filter((originalValue) => !values.includes(originalValue)),
      measuringUnit: getMeasuringUnitTranslation(attributeId, locale, intl),
      noMeasuringUnitIfValueContains: (additionalAttrMetaData)
        ? additionalAttrMetaData.noMeasuringUnitIfValueContains : undefined,
      currency,
      locale,
      hasFromToValues,
    };
  });
}

function getAttributeData(product, attributeId, locale, intl) {
  const attributeData = {};
  const additionalAttrMetaData = getAdditionalAttributeMetaData(
    attributeId
  );
  if (additionalAttrMetaData) {
    attributeData.label = additionalAttrMetaData.names[locale];
    attributeData.values = getAdditionalAttributeValue(
      product,
      attributeId,
      additionalAttrMetaData.i18nImpl,
      additionalAttrMetaData.i18nIdUndefined,
      locale
    );
    if (additionalAttrMetaData.acronyms) {
      attributeData.acronym = additionalAttrMetaData.acronyms[locale];
    }
    if (additionalAttrMetaData.measuringUnit) {
      attributeData.measuringUnit = additionalAttrMetaData.measuringUnit[locale];
    }
    attributeData.noMeasuringUnitIfValueContains = additionalAttrMetaData
      .noMeasuringUnitIfValueContains;
  } else {
    attributeData.label = getTranslationOfBaseAttribute(attributeId, intl);
    attributeData.values = getBaseAttributeValue(
      product,
      attributeId,
      locale
    );
    attributeData.measuringUnit = getTranslationOfBaseAttributeMeasuringUnit(
      attributeId, locale, intl
    );
  }
  return attributeData;
}

function getMeasuringUnitForAttrValue(attrValue, measuringUnit, noMeasuringUnitIfValueContains) {
  if (!measuringUnit) {
    return undefined;
  } else if (noMeasuringUnitIfValueContains) {
    let tmpAttrValue = attrValue;
    if (tmpAttrValue !== 'string') {
      tmpAttrValue = tmpAttrValue.toString();
    }
    for (let i = 0; i < noMeasuringUnitIfValueContains.length; i++) {
      if (tmpAttrValue.includes(noMeasuringUnitIfValueContains[i])) {
        return undefined;
      }
    }
  }
  return measuringUnit;
}

function attributeValuesToString(
  attributeValues, measuringUnit, noMeasuringUnitIfValueContains, intl
) {
  let value;
  if (attributeValues === undefined) {
    return attributeValues;
  } else if (attributeValues.from !== undefined && attributeValues.to !== undefined) {
    return `${attributeValues.from}${(getMeasuringUnitForAttrValue(attributeValues.from, measuringUnit, noMeasuringUnitIfValueContains) !== undefined) ? measuringUnit : ''} ${intl.formatMessage({ id: 'rangeTo' })} ${attributeValues.to}${(getMeasuringUnitForAttrValue(attributeValues.to, measuringUnit, noMeasuringUnitIfValueContains) !== undefined) ? measuringUnit : ''}`;
  } else if (!Array.isArray(attributeValues)) {
    return `${attributeValues} ${(getMeasuringUnitForAttrValue(attributeValues, measuringUnit, noMeasuringUnitIfValueContains) !== undefined) ? measuringUnit : ''}`;
  }
  for (let i = 0; i < attributeValues.length; i++) {
    if (i === 0) {
      value = `${attributeValues[i]}${(getMeasuringUnitForAttrValue(attributeValues[i], measuringUnit, noMeasuringUnitIfValueContains) !== undefined) ? measuringUnit : ''}`;
    } else {
      value = `${value}, ${attributeValues[i]}${(getMeasuringUnitForAttrValue(attributeValues[i], measuringUnit, noMeasuringUnitIfValueContains) !== undefined) ? measuringUnit : ''}`;
    }
  }
  return value;
}

function getAdditionalAttributeIds(product) {
  const { noI18n, i18nId } = product.additionalAttributes;
  const i18nIdAttributeIds = (i18nId) ? Object.keys(i18nId) : [];
  return (noI18n) ? i18nIdAttributeIds.concat(Object.keys(noI18n)) : i18nIdAttributeIds;
}

function getFilterSummary(filters, locale, intl) {
  return Object.keys(filters).map((attributeId) => ({
    attributeId,
    selectedOptions: filters[attributeId],
    heading: getAdditionalAttributeName(attributeId, locale)
      || getTranslationOfBaseAttribute(attributeId, intl),
    measuringUnit: getMeasuringUnitTranslation(attributeId, locale, intl),
  }));
}

function sortProductCategoryArray(productCategories, locale) {
  const tmpProductCategories = productCategories;
  return tmpProductCategories.sort((a, b) => (
    a.name[locale].localeCompare(b.name[locale], locale, {
      numeric: true,
      sensitivity: 'base',
    })
  ));
}

function sortProductsByNetPrice(products, sortOrder, locale, dataStorage) {
  const netPrices = {};
  products.forEach((product) => {
    netPrices[product.id] = getProductNetPrice(product, locale, dataStorage);
  });
  const sortedProducts = products;
  sortedProducts.sort((a, b) => (
    (sortOrder === 'ASC')
      ? netPrices[a.id] - netPrices[b.id]
      : netPrices[b.id] - netPrices[a.id]
  ));
  return sortedProducts;
}

function getFilteredAndSortedProducts(
  products, activeFilters, activeSortOption, locale, dataStorage
) {
  const filteredProductList = getFilteredProductList(products, activeFilters, locale, dataStorage);
  if (activeSortOption
    && activeSortOption.propertyPath
    && activeSortOption.propertyPath[activeSortOption.propertyPath.length - 1] === 'price'
  ) {
    return sortProductsByNetPrice(
      filteredProductList, activeSortOption.order, locale, dataStorage
    );
  }
  return listService.sortList(
    filteredProductList,
    activeSortOption.propertyPath,
    activeSortOption.order,
    locale
  );
}

function getSortAttributeSettings(customSortSettingsIdOrArray) {
  const customSortSettings = (Array.isArray(customSortSettingsIdOrArray))
    ? customSortSettingsIdOrArray : getProductConfig().sortOptions[customSortSettingsIdOrArray];
  const defaultSortSettings = getProductConfig().sortOptions.default;
  if (!customSortSettings || customSortSettings.length === 0) {
    return defaultSortSettings;
  }
  let customPreset = false;
  customSortSettings.forEach((customSortSetting) => {
    if (customSortSetting.preset) {
      customPreset = true;
    }
  });
  let tmpDefaultSortSettings = defaultSortSettings;
  if (customPreset) {
    tmpDefaultSortSettings = defaultSortSettings.map((defaultSortSetting) => ({
      name: defaultSortSetting.name,
      propertyPath: defaultSortSetting.propertyPath,
      orders: defaultSortSetting.orders,
      type: defaultSortSetting.type,
    }));
  }
  return tmpDefaultSortSettings.concat(customSortSettings);
}

function getDefaultProductListSortOption(sortAttributeSettings, intl, locale) {
  return listService.getDefaultSortOption(sortAttributeSettings, intl, locale);
}

function getProductListSortOptions(sortAttributeSettings, intl, locale) {
  return listService.getSortOptions(sortAttributeSettings, intl, locale);
}

function getSortDropdownItems(sortOptions, activeSortOption, onClickHandler) {
  return listService.getSortDropdownItems(sortOptions, activeSortOption, onClickHandler);
}

function getListItemDescrition(product, locale, intl) {
  const productTypeConfig = getProductTypeConfigByProduct(product);
  if (productTypeConfig && productTypeConfig.listItemAttributes) {
    let descrition = '';
    const attributes = productTypeConfig.listItemAttributes;
    for (let i = 0; i < attributes.length; i++) {
      let attributeString;
      const attributeData = getAttributeData(product, attributes[i].id, locale, intl);
      const value = attributeValuesToString(
        attributeData.values,
        attributeData.measuringUnit,
        attributeData.noMeasuringUnitIfValueContains,
        intl
      );
      if (value) {
        if (attributes[i].layout === 'Acronym') {
          attributeString = `${attributeData.acronym}: ${value}`;
        } else if (attributes[i].layout === 'Label') {
          attributeString = `${attributeData.label}: ${value}`;
        } else {
          attributeString = value;
        }
        descrition = (i === 0 || (i === 1 && descrition === ''))
          ? attributeString : `${descrition}, ${attributeString}`;
      }
    }
    return descrition;
  }
  return getAttributeValue(product, 'productType', locale);
}

function getAvailabilityData(product, intl, quantity) {
  const qty = quantity || 1;
  const availabilityData = {};
  if (product.quantityInStock >= qty) {
    availabilityData.label = intl.formatMessage({ id: 'deliverableImmediately' });
    availabilityData.statusCode = 0;
  } else if (product.quantityInStock > 0) {
    // availabilityData.label = intl.formatMessage({ id: 'partiallyInStock' });
    availabilityData.label = intl.formatMessage({ id: 'deliverable' });
    availabilityData.statusCode = 1;
  } else {
    availabilityData.label = intl.formatMessage({ id: 'deliverable' });
    availabilityData.statusCode = 2;
  }
  return availabilityData;
}

function getOrderQuantityInfo(product) {
  const orderQuantityInfo = {
    hasDecimalOrderQuantity: false,
    hasIncrement: false,
    showButtons: false,
  };
  if (product) {
    const productTypeConfig = getProductTypeConfigByProduct(product);
    if (productTypeConfig
      && productTypeConfig.orderQuantity
    ) {
      if (productTypeConfig.orderQuantity.dataType === 'Decimal') {
        orderQuantityInfo.hasDecimalOrderQuantity = true;
      }
      if (productTypeConfig.orderQuantity.showButtons) {
        orderQuantityInfo.showButtons = true;
      }
      if (productTypeConfig.orderQuantity.hasIncrement) {
        orderQuantityInfo.hasIncrement = true;
        orderQuantityInfo.increment = productTypeConfig.orderQuantity.increment
        || product.packagingUnit;
      }
      orderQuantityInfo.min = productTypeConfig.orderQuantity.min;
      orderQuantityInfo.max = productTypeConfig.orderQuantity.max;
    }
  }
  return orderQuantityInfo;
}

function isValidOrderQuantity(orderQuantity, product, customOrderQuantityInfo) {
  if (product) {
    const orderQuantityInfo = customOrderQuantityInfo || getOrderQuantityInfo(product);
    if (orderQuantityInfo) {
      const validatorFunc = (orderQuantityInfo.hasDecimalOrderQuantity)
        ? numberHelper.canBeConvertedToNumber : numberHelper.canBeConvertedToInteger;
      return validatorFunc(orderQuantity) && ((orderQuantityInfo.min)
        ? orderQuantity >= orderQuantityInfo.min : orderQuantity > 0)
        && (!orderQuantityInfo.max || orderQuantityInfo.max >= orderQuantity)
        && (!orderQuantityInfo.hasIncrement || (numberHelper.modulo(
          Number(orderQuantity), orderQuantityInfo.increment || product.packagingUnit
        ) === 0));
    }
  }
  return numberHelper.canBeConvertedToInteger(orderQuantity) && orderQuantity > 0;
}

function getFormattedPrice(price, locale) {
  return price.toLocaleString(locale, { style: 'currency', currency: configService.getGeneralConfig().sales.currency });
}

function searchProductOrCategoryCompareFunc(
  item, searchTermParts, searchTermPartWeights, compareFuncCustomParams
) {
  const { itemType, intl, locale } = compareFuncCustomParams;
  searchTermParts.forEach((searchTermPart) => {
    if (item.id.toUpperCase() === searchTermPart) {
      searchTermPartWeights[searchTermPart] += 50;
    }
    if (itemType === 'product' && item.id.toUpperCase().startsWith(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 1;
    }
    if (item.name[locale].toUpperCase().startsWith(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 10;
      if (item.name[locale].length !== searchTermPart.length
        && item.name[locale].toUpperCase().startsWith(`${searchTermPart} `)
      ) {
        searchTermPartWeights[searchTermPart] += 1;
      }
    } else if (item.name[locale].toUpperCase().replaceAll(' ', '').startsWith(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 6;
    } else if (item.name[locale].toUpperCase().replaceAll(' ', '').startsWith(searchTermPart.replaceAll('-', ''))) {
      searchTermPartWeights[searchTermPart] += 3;
    }
    if (item.name[locale].toUpperCase().includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 4;
      if (item.name[locale].toUpperCase().includes(` ${searchTermPart} `) || item.name[locale].toUpperCase().endsWith(` ${searchTermPart}`)) {
        searchTermPartWeights[searchTermPart] += 1;
      }
    } else if (searchTermPart.length > 2 && item.name[locale].toUpperCase().replaceAll(' ', '').includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 2;
    } else if (searchTermPart.length > 3 && item.name[locale].toUpperCase().replaceAll(' ', '').includes(searchTermPart.replaceAll('-', ''))) {
      searchTermPartWeights[searchTermPart] += 1;
    }
    if (itemType === 'product' && getAttributeValueTranslation(
      item.productType.i18nId, undefined, locale
    ).toUpperCase().includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 3;
    }
    if (item.description[locale].toUpperCase().includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 1;
    }
    if (Array.isArray(item.synonyms)) {
      item.synonyms.forEach((synonym) => {
        if (synonym.toUpperCase() === searchTermPart) {
          searchTermPartWeights[searchTermPart] += 8;
        } else if (searchTermPart.length > 4 && synonym.toUpperCase().replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')
          .startsWith(searchTermPart.replaceAll('-', '').replaceAll('/', ''))) {
          searchTermPartWeights[searchTermPart] += 5;
        } else if (searchTermPart.length > 4 && synonym.toUpperCase().includes(searchTermPart)) {
          searchTermPartWeights[searchTermPart] += 1;
        }
      });
    }
  });
  if (itemType === 'product') {
    getAdditionalAttributeIds(item).forEach((attributeId) => {
      const attributeData = getAttributeData(item, attributeId, locale, intl);
      if (attributeData) {
        if (attributeData.values !== null) {
          const attributeValuesString = attributeValuesToString(
            attributeData.values,
            attributeData.measuringUnit,
            attributeData.noMeasuringUnitIfValueContains,
            intl
          );
          if (attributeValuesString) {
            searchTermParts.forEach((searchTermPart) => {
              try {
                if (attributeValuesString.toUpperCase().includes(searchTermPart)) {
                  searchTermPartWeights[searchTermPart] += 1;
                }
              } catch (err) {
                console.log(attributeData);
                console.log(attributeValuesString);
                console.log(item);
                throw err;
              }
            });
          }
        } else if (!attrValueNullDetected) {
          attrValueNullDetected = true;
          const errMsg = `Value of attribute ${attributeId} of product ${item.id} is null`;
          console.error(errMsg);
          console.log(item);
          apiService.post('/system/frontend-error', {
            errMsg,
            errInfo: { productId: item.id, method: 'searchProductOrCategoryCompareFunc' },
          });
        }
      }
    });
  }
}

function getSearchConfig(productOrCategoryConfig) {
  const searchConfig = {
    logicOperator: 'OR',
    termAdjustments: [],
  };
  if (productOrCategoryConfig.search) {
    if (productOrCategoryConfig.search.logicOperator) {
      searchConfig.logicOperator = productOrCategoryConfig.search.logicOperator;
    }
    if (productOrCategoryConfig.search.termAdjustments) {
      searchConfig.termAdjustments = productOrCategoryConfig.search.termAdjustments;
    }
  }
  return searchConfig;
}

function searchProducts(products, searchTerm, logicOperator, locale, intl, sortList) {
  const searchConfig = getSearchConfig(getProductConfig());
  return listService.search(
    products,
    listService.getAdjustedSearchTerm(searchTerm, searchConfig.termAdjustments),
    logicOperator || searchConfig.logicOperator,
    locale,
    searchProductOrCategoryCompareFunc,
    {
      itemType: 'product', intl, locale,
    },
    sortList
  );
}

function searchProductCategories(
  productCategories, searchTerm, locale, intl, sortList
) {
  const searchConfig = getSearchConfig(getProductConfig().category);
  return listService.search(
    productCategories,
    listService.getAdjustedSearchTerm(searchTerm, searchConfig.termAdjustments),
    searchConfig.logicOperator, locale, searchProductOrCategoryCompareFunc,
    {
      itemType: 'productCategories', intl, locale,
    },
    sortList
  );
}

function getSearchedFilteredAndSortedProducts(
  products,
  activeFilters,
  activeSort,
  searchTerm,
  logicOperator,
  locale,
  intl,
  sortList,
  dataStorage
) {
  return searchProducts(getFilteredAndSortedProducts(
    products, activeFilters, activeSort, locale, dataStorage
  ), searchTerm, logicOperator, locale, intl, sortList);
}

function generateImgFilename(product, size) {
  const imgSettings = getProductConfig().image;
  return `${product.id}-${imgSettings.defaultImgSizes[size]}.${imgSettings.defaultImgExtentions[size]}`;
}

function getImgFilename(product, size) {
  let filename;
  if (size === 'thumbnail') {
    filename = product.imageThumbnail || generateImgFilename(product, size);
  } else if (size === 'small') {
    filename = product.imageSmall || generateImgFilename(product, size);
  } else {
    filename = product.image || generateImgFilename(product, 'base');
  }
  return filename;
}

function getImgPath(product, size) {
  return configService.getProductImgPath(getImgFilename(product, size));
}

function getAdditionalImgPath(filename) {
  return configService.getProductImgPath(filename);
}

function enrichPageBuilderParam(param, items, locale) {
  let item;
  if (param.productId) {
    item = getProduct(param.productId, items);
    param.href = getCanonicalProductPath(item, items, locale);
  } else {
    item = getProductCategory(param.productCategoryId, items);
    if (!item) {
      console.error(`No product category found for id: ${param.productCategoryId}`);
    }
    param.href = getCanonicalProductCategoryPath(item, items, locale);
  }
  if (!param.heading) {
    param.heading = item.name[locale];
  }
  if (!param.imgAlt) {
    param.imgAlt = param.heading;
  }
  if (!param.imgSrc) {
    param.imgSrc = getImgPath(item);
  }
}

function enrichCategoryMenuItem(item, productCategories, languageCode) {
  if (item.productCategoryId) {
    const productCategory = getProductCategory(item.productCategoryId, productCategories);
    if (!productCategory) {
      item.invalid = true;
    } else {
      if (item.useProductCategoryMenu) {
        item.subitems = productCategory.menu[languageCode].subitems;
        item.image = productCategory.menu[languageCode].image;
        if (productCategory.menu[languageCode].label) {
          item.label = productCategory.menu[languageCode].label;
        }
      }
      if (!item.link) {
        item.link = getCanonicalProductCategoryPath(
          productCategory, productCategories, languageCode
        );
      }
      if (!item.label) {
        item.label = productCategory.name[languageCode];
      }
      item.imgSrc = item.imgSrc || getImgPath(productCategory, 'small');
    }
  }
}

function addItemToCategoryMenuItemArray(item, menuItemArray, locale, productCategories) {
  if (item.id) {
    configService.enrichMenuItem(item);
  } else if (item.productCategoryId && !item.link) {
    enrichCategoryMenuItem(item, productCategories, locale);
  }
  if (item.link) {
    menuItemArray.push(item);
  }
}

function getProductCategoryMenuItemArray(categoryMenu, productCategories, sortSettings, locale) {
  let menuItemArray = [];
  categoryMenu[locale].subitems.forEach((item) => {
    addItemToCategoryMenuItemArray(item, menuItemArray, locale, productCategories);
    if (item.subitems) {
      item.subitems.forEach((subitem) => {
        addItemToCategoryMenuItemArray(subitem, menuItemArray, locale, productCategories);
      });
    }
  });
  if (sortSettings) {
    if (sortSettings.sort) {
      menuItemArray.sort((a, b) => (
        a.label.localeCompare(b.label, locale, {
          numeric: true,
          sensitivity: 'base',
        })
      ));
    }
    if (sortSettings.order) {
      const topMenuItems = [];
      const restMenuItems = [];
      sortSettings.order.forEach((sortOrderItem) => {
        let match = false;
        for (let i = 0; i < menuItemArray.length && !match; i++) {
          if (sortOrderItem.id && sortOrderItem.id === menuItemArray[i].id) {
            topMenuItems.push(menuItemArray[i]);
            match = true;
          }
          if (sortOrderItem.productCategoryId
            && sortOrderItem.productCategoryId === menuItemArray[i].productCategoryId) {
            topMenuItems.push(menuItemArray[i]);
            match = true;
          }
        }
      });
      menuItemArray.forEach((menuItem) => {
        let match = false;
        for (let i = 0; i < topMenuItems.length && !match; i++) {
          if (menuItem.label === topMenuItems[i].label) {
            restMenuItems.push(menuItem);
            match = true;
          }
        }
      });
      menuItemArray = topMenuItems.concat(restMenuItems);
    }
  }
  return menuItemArray;
}

function enrichFrontendMenus(frontendMenus, productCategories) {
  frontendMenus.forEach((frontendMenu) => {
    Object.keys(frontendMenu.languageVersions).forEach((languageCode) => {
      frontendMenu.languageVersions[languageCode].forEach((firstLevelItem) => {
        if (firstLevelItem.productCategoryId) {
          enrichCategoryMenuItem(firstLevelItem, productCategories, languageCode);
        }
        if (firstLevelItem.image && firstLevelItem.image.productCategoryId) {
          enrichCategoryMenuItem(firstLevelItem.image, productCategories, languageCode);
        }
        if (firstLevelItem.subitems) {
          firstLevelItem.subitems.forEach((secondLevelItem) => {
            if (secondLevelItem.productCategoryId) {
              enrichCategoryMenuItem(secondLevelItem, productCategories, languageCode);
            }
            if (secondLevelItem.subitems) {
              secondLevelItem.subitems.forEach((thirdLevelItem) => {
                if (thirdLevelItem.productCategoryId) {
                  enrichCategoryMenuItem(thirdLevelItem, productCategories, languageCode);
                }
              });
              secondLevelItem.subitems = secondLevelItem.subitems.filter(
                (thirdLevelItem) => !thirdLevelItem.invalid
              );
            }
          });
          firstLevelItem.subitems = firstLevelItem.subitems.filter(
            (secondLevelItem) => !secondLevelItem.invalid
          );
        }
      });
      frontendMenu.languageVersions[languageCode] = frontendMenu
        .languageVersions[languageCode].filter((firstLevelItem) => !firstLevelItem.invalid);
    });
  });
  return frontendMenus;
}

function addParentCategoriesToBeadcrumbsData(
  breadcrumbsData, productCategory, productCategories, locale
) {
  const parentCategories = addCanonicalParentCategory([], productCategory, productCategories);
  parentCategories.forEach((parentCategory) => breadcrumbsData.push({
    label: parentCategory.name[locale],
    href: getCanonicalProductCategoryPath(parentCategory, productCategories, locale),
  }));
}

function getCategoryBreadcrumbsData(productCategory, productCategories, locale) {
  const breadcrumbsData = [];
  if (productCategory.canonicalParentCategoryId) {
    addParentCategoriesToBeadcrumbsData(
      breadcrumbsData, productCategory, productCategories, locale
    );
  }
  breadcrumbsData.push({
    label: productCategory.name[locale],
  });
  return breadcrumbsData;
}

function getProductBreadcrumbsData(product, productCategories, locale) {
  const breadcrumbsData = [];
  const slugs = window.location.pathname.split('/');
  slugs.pop();
  const directParentCategory = getProductCategoryBySlug(
    slugs[slugs.length - 1], productCategories, locale
  );
  if (directParentCategory) {
    if (directParentCategory.canonicalParentCategoryId) {
      addParentCategoriesToBeadcrumbsData(
        breadcrumbsData, directParentCategory, productCategories, locale
      );
    }
    breadcrumbsData.push({
      label: directParentCategory.name[locale],
      href: getCanonicalProductCategoryPath(directParentCategory, productCategories, locale),
    });
  }
  breadcrumbsData.push({
    label: product.name[locale],
  });
  return breadcrumbsData;
}

function getProductCategoryListItemData(productCategory, productCategories, locale) {
  return {
    label: productCategory.name[locale],
    href: getCanonicalProductCategoryPath(productCategory, productCategories, locale),
    imgSrc: getImgPath(productCategory, 'small'),
  };
}

function productConfigSettingDisabledForProduct(product, setting) {
  return product.productTypeConfigChanges
    && product.productTypeConfigChanges.disable
    && product.productTypeConfigChanges.disable[setting];
}

function filterAdditionalImagesByLocale(additionalImages, locale) {
  if (!additionalImages) {
    return [];
  }
  const filteredImages = [];
  additionalImages.forEach((additionalImage) => {
    if (additionalImage.label[locale]) {
      filteredImages.push(
        { src: getAdditionalImgPath(additionalImage.filename), alt: additionalImage.label[locale] }
      );
    }
  });
  return filteredImages;
}

function getImages(productTypeConfig, product, locale) {
  const images = filterAdditionalImagesByLocale(
    (!productConfigSettingDisabledForProduct(product, 'additionalImages'))
      ? productTypeConfig.additionalImages : undefined,
    locale
  ).concat(filterAdditionalImagesByLocale(product.additionalImages, locale));
  images.unshift(
    { src: getAdditionalImgPath(product.image), alt: product.name[locale] }
  );
  return images;
}

function filterAdditionalInfoByLocale(items, locale) {
  if (!items || !items[locale]) {
    return [];
  }
  const filteredItems = [];
  items[locale].forEach((item) => {
    filteredItems.push(item);
  });
  return filteredItems;
}

function sortAdditionalInfoByPriority(infoArray) {
  const topInfo = [];
  const bottomInfo = [];
  infoArray.forEach((infoItem) => {
    if (infoItem.displayPos === 'before') {
      topInfo.push(infoItem);
    } else {
      bottomInfo.push(infoItem);
    }
  });
  return topInfo.concat(bottomInfo);
}

function getAdditionalInfoByLocale(productTypeConfig, product, type, locale) {
  return sortAdditionalInfoByPriority(filterAdditionalInfoByLocale(
    (!productConfigSettingDisabledForProduct(product, type)) ? productTypeConfig[type] : undefined,
    locale
  ).concat(filterAdditionalInfoByLocale(product[type], locale)));
}

function getRelatedProductRules(productTypeConfig, product, type) {
  if (product.relatedProducts && product.relatedProducts[type]) {
    return product.relatedProducts[type];
  } else if (productTypeConfig
    && productTypeConfig.relatedProducts && productTypeConfig.relatedProducts[type]) {
    return productTypeConfig.relatedProducts[type];
  } else if (type === 'similar') {
    return [[{ attr: 'productType' }]];
  }
  return undefined;
}

function productsAreRelated(productA, productB, rules, locale, alreadyFoundRelatedProducts) {
  let match = false;
  if (productA.id === productB.id) {
    return false;
  }
  for (let i = 0; i < rules.length; i++) {
    if (rules[i].thisAttr && !rules[i].attr) {
      if (rules[i].operator === 'IN' && rules[i].thisValue) {
        if (!Array.isArray(rules[i].thisValue)) {
          return false;
        }
        const productAValue = getAttributeValue(productA, rules[i].thisAttr, locale);
        let isNumericArray = true;
        rules[i].thisValue.forEach((ele) => {
          if (typeof ele !== 'number') {
            isNumericArray = false;
          }
        });
        const productBValueArr = (isNumericArray) ? rules[i].thisValue : rules[i].thisValue.map(
          (val) => getAttributeValueTranslation(val, undefined, locale)
        );
        if (productBValueArr.includes(productAValue)) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === 'INCLUDES' && rules[i].thisValue) {
        const arrA = getAttributeValue(productA, rules[i].thisAttr, locale);
        const value = getAttributeValueTranslation(rules[i].thisValue, undefined, locale, true)
        || rules[i].thisValue;
        if (Array.isArray(arrA) && arrA.includes(value)) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '=' && rules[i].thisValue) {
        const value = getAttributeValueTranslation(rules[i].thisValue, undefined, locale, true)
          || rules[i].thisValue;
        const productAValue = getAttributeValue(productA, rules[i].thisAttr, locale);
        if ((productAValue === value && value !== undefined)
          || arrayHelper.compareSimpleArrays(value, productAValue)
        ) {
          match = true;
        } else {
          return false;
        }
      }
    } else if (rules[i].thisAttr && rules[i].attr) {
      if (rules[i].operator === 'IN') {
        const arr = getAttributeValue(productB, rules[i].attr, locale);
        if (Array.isArray(arr) && arr.includes(
          getAttributeValue(productA, rules[i].thisAttr, locale)
        )) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '1EleIN') {
        const arrA = getAttributeValue(productA, rules[i].thisAttr, locale);
        const arrB = getAttributeValue(productB, rules[i].attr, locale);
        if (!Array.isArray(arrA) || !Array.isArray(arrB)) {
          return false;
        }
        let oneEleIn = false;
        for (i = 0; i < arrA.length && !oneEleIn; i++) {
          if (arrB.includes(arrA[i])) {
            oneEleIn = true;
          }
        }
        if (oneEleIn) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === 'INCLUDES') {
        const arr = getAttributeValue(productA, rules[i].thisAttr, locale);
        if (Array.isArray(arr) && arr.includes(
          getAttributeValue(productB, rules[i].attr, locale)
        )) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '=') {
        const value = getAttributeValue(productA, rules[i].thisAttr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if ((productBValue === value && value !== undefined)
          || arrayHelper.compareSimpleArrays(value, productBValue)
        ) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '<=') {
        const value = getAttributeValue(productA, rules[i].thisAttr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if (value !== undefined && !Array.isArray(value)
          && !Array.isArray(productBValue) && productBValue >= value
        ) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '>=') {
        const value = getAttributeValue(productA, rules[i].thisAttr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if (value !== undefined && !Array.isArray(value)
          && !Array.isArray(productBValue) && productBValue <= value
        ) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '>' || rules[i].operator === '>[next]') {
        const value = getAttributeValue(productA, rules[i].thisAttr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if (value > productBValue) {
          if (rules[i].operator === '>[next]' && alreadyFoundRelatedProducts) {
            if (productBValue > getAttributeValue(
              alreadyFoundRelatedProducts[0], rules[i].attr, locale
            )) {
              alreadyFoundRelatedProducts = undefined;
            } else {
              return false;
            }
          }
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '<' || rules[i].operator === '<[next]') {
        const value = getAttributeValue(productA, rules[i].thisAttr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if (value < productBValue) {
          if (rules[i].operator === '<[next]' && alreadyFoundRelatedProducts) {
            if (productBValue < getAttributeValue(
              alreadyFoundRelatedProducts[0], rules[i].attr, locale
            )) {
              alreadyFoundRelatedProducts = undefined;
            } else {
              return false;
            }
          }
          match = true;
        } else {
          return false;
        }
      }
    } else if (!rules[i].thisAttr && rules[i].attr) {
      if (rules[i].attr === 'productType') {
        if ((!rules[i].value && productA.productType.i18nId === productB.productType.i18nId)
          || (rules[i].value && productB.productType.i18nId === rules[i].value)) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '=') {
        let value;
        if (rules[i].value) {
          value = getAttributeValueTranslation(rules[i].value, undefined, locale, true)
          || rules[i].value;
        } else {
          value = getAttributeValue(productA, rules[i].attr, locale);
        }
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if ((productBValue === value && value !== undefined)
          || arrayHelper.compareSimpleArrays(value, productBValue)
        ) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === '!=') {
        const value = getAttributeValueTranslation(rules[i].value, undefined, locale, true)
          || getAttributeValue(productA, rules[i].attr, locale);
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        if ((!Array.isArray(productBValue) && productBValue !== value)
          || (Array.isArray(productBValue)
          && !arrayHelper.compareSimpleArrays(value, productBValue))) {
          match = true;
        } else {
          return false;
        }
      } else if (rules[i].operator === 'IN') {
        if (!Array.isArray(rules[i].value)) {
          return false;
        }
        const productBValue = getAttributeValue(productB, rules[i].attr, locale);
        let isNumericArray = true;
        rules[i].value.forEach((ele) => {
          if (typeof ele !== 'number') {
            isNumericArray = false;
          }
        });
        const productAValueArr = (isNumericArray) ? rules[i].value : rules[i].value.map(
          (val) => getAttributeValueTranslation(val, undefined, locale)
        );
        if (productAValueArr.includes(productBValue)) {
          match = true;
        } else {
          return false;
        }
      }
    }
  }
  return match;
}

function getRelatedProducts(
  productTypeConfig, product, type, products, locale, maxCount, customRules
) {
  let matches = 0;
  const relatedProducts = {};
  const rules = customRules || getRelatedProductRules(productTypeConfig, product, type);
  if (!rules) {
    return [];
  }
  for (let i = 0; i < products.length && (matches / rules.length) < maxCount; i++) {
    for (let k = 0; k < rules.length; k++) {
      if ((!relatedProducts[k] || relatedProducts[k].length < maxCount)
        && productsAreRelated(product, products[i], rules[k], locale, relatedProducts[k])
      ) {
        matches += 1;
        if (relatedProducts[k]) {
          relatedProducts[k].push(products[i]);
        } else {
          relatedProducts[k] = [products[i]];
        }
      }
    }
  }
  const rulesWithMatches = Object.keys(relatedProducts).length;
  if (rulesWithMatches > 1) {
    const relatedProductsArray = [];
    for (let i = 0; i < maxCount && relatedProductsArray.length < maxCount; i++) {
      Object.keys(relatedProducts).forEach((ruleNumber) => {
        if (relatedProducts[ruleNumber][i]
          && relatedProductsArray.length < maxCount
          && !relatedProductsArray.includes(relatedProducts[ruleNumber][i])
        ) {
          relatedProductsArray.push(relatedProducts[ruleNumber][i]);
        }
      });
    }
    return relatedProductsArray;
  } else if (rulesWithMatches === 1) {
    return relatedProducts[Object.keys(relatedProducts)[0]];
  }
  return [];
}

function getMetaDescription(item, locale) {
  if (item.metaDescription && item.metaDescription[locale]) {
    return item.metaDescription[locale];
  } else if (item.shortDescription && item.shortDescription[locale]) {
    return item.shortDescription[locale];
  }
  return item.description[locale];
}

function getHeadData(item, locale, dataStorage, isCategory) {
  const imgUrl = configService.getUrl(getImgPath(item));
  const canonicalUrl = (!isCategory) ? getCanonicalUrl(
    item,
    getProductConfig().canonical,
    locale
  ) : getCanonicalProductCategoryUrl(item, dataStorage.productCategories, locale);
  const headData = {
    label: item.name[locale],
    description: getMetaDescription(item, locale),
    canonicalUrl,
    image: imgUrl,
  };
  if (!isCategory) {
    headData.jsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      sku: item.id,
      name: item.name[locale],
      description: (item.shortDescription)
        ? item.shortDescription[locale] : item.description[locale],
      image: [imgUrl],
      offers: {
        '@type': 'Offer',
        url: canonicalUrl,
        priceCurrency: getCurrency(),
        price: getProductNetPrice(item, locale, dataStorage),
        availability: (item.quantityInStock > 0) ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      },
    };
  }
  return headData;
}

export default {
  setDependencies,
  getProductConfig,
  getProductCategoryConfig,
  getProductCadConfig,
  getProductTypeConfigById,
  getProductTypeConfigByProduct,
  getOrderQuantityTypeByProduct,
  getImages,
  getAdditionalInfoByLocale,
  getProduct,
  getProductCategory,
  getProductCategoryBySlug,
  getPageInfo,
  enrichFrontendMenus,
  getHeadData,
  getCanonicalProductPath,
  getCanonicalProductUrl,
  getCanonicalProductCategoryPathById,
  enrichPageBuilderParam,
  getProductHrefOnProductItem,
  getTranslatedPagePath,
  getAdditionalAttributeMetaData,
  getAdditionalAttributeName,
  getAttributeValue,
  getTranslationOfBaseAttribute,
  getAttributeValueTranslation,
  getMeasuringUnitForAttrValue,
  getFormattedQuantityAndUnit,
  getPositionPriceInfoObject,
  getPositionPriceInfo,
  getFilterAttributes,
  getFilterAttributesForSearchPage,
  buildFilterAttributeIds,
  getProductCategoryMenuItemArray,
  getFilteredProductList,
  getProductsForCategory,
  getFilterListMetaData,
  getAdditionalAttributeIds,
  getFilterSummary,
  sortProductCategoryArray,
  getFilteredAndSortedProducts,
  getSortAttributeSettings,
  getDefaultProductListSortOption,
  getProductListSortOptions,
  getSortDropdownItems,
  getAttributeData,
  attributeValuesToString,
  getListItemDescrition,
  getAvailabilityData,
  getFormattedPrice,
  isValidOrderQuantity,
  getDefaultOrderQuantity,
  getOrderQuantityInfo,
  searchProducts,
  searchProductCategories,
  getSearchedFilteredAndSortedProducts,
  getImgFilename,
  getImgPath,
  getAdditionalImgPath,
  getCategoryBreadcrumbsData,
  getProductBreadcrumbsData,
  getProductCategoryListItemData,
  getRelatedProducts,
};
